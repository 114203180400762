import { detectClientIp } from '../../workers/ip';
import { useToken } from "../../components/utils/useToken";
import { promotionRequest } from '../../workers/cms';
import { APP_ASSETS_URL } from "../../variables";

const GET_CONTENT = 'GET_CONTENT';
const GET_ALL_CMS_CONTENT = 'GET_ALL_CMS_CONTENT';
const DETECT_CLIENT_IP = 'DETECT_CLIENT_IP';
const SHOW_COOKIES_BAR = 'SHOW_COOKIES_BAR';
const CMS_CONTENT_REQUESTING = 'CMS_CONTENT_REQUESTING';
const GET_PROMOTION = 'GET_PROMOTION';
const GET_PROMOTION_ERROR = 'GET_PROMOTION_ERROR';
const CLEAN_PROMOTION = 'CLEAN_PROMOTION';

export const initialState = {
    content: {
        logo: `${APP_ASSETS_URL}/images/logo_favIcons/sultanvip_logo.png`,
        favicon: [
            {
                url: `${APP_ASSETS_URL}/images/logo_favIcons/sultan_favicon_256.png`,
                dimension: '256x256'
            },
            {
                url: `${APP_ASSETS_URL}/images/logo_favIcons/sultan_favicon_36.png`,
                dimension: '36x36'
            }
            ,
            {
                url: `${APP_ASSETS_URL}/images/logo_favIcons/sultan_favicon_48.png`,
                dimension: '48x48'
            }
            ,
            {
                url: `${APP_ASSETS_URL}/images/logo_favIcons/sultan_favicon_72.png`,
                dimension: '72x72'
            }
        ],
        status: '',
        maintenanceWhitelistIps: []
    },
    isContentLoaded: false,
    isLoading: true,
    failRequest: false,
    cmsPages: [
        {
            id: 'responsible_gaming',
            translation: 'messages_menu_responsible',
            content: '',
        },
        {
            id: 'account_verification',
            translation: 'messages_menu_account',
            content: '',
        },
        {
            id: 'personal_data',
            translation: 'messages_menu_security',
            content: '',
        },
        {
            id: 'payment_methods',
            translation: 'messages_menu_payment',
            content: '',
        },
        {
            id: 'mailto:affiliates@sultanvip.com',
            translation: 'messages_menu_affiliation',
            content: '',
        },
        {
            id: 'about',
            translation: 'messages_menu_about',
            content: '',
        },
        {
            id: 'legal_mention',
            translation: 'messages_menu_lm',
            content: '',
        },
        {
            id: 'terms_and_conditions',
            translation: 'messages_menu_terms',
            content: '',
        },
        {
            id: 'self-exclusion',
            url: 'self-exclusion.html',
            translation: 'messages_menu_self_exclusion',
            content: ''
        },
        {
            id: 'dispute-resolution',
            url: 'dispute-resolution.html',
            translation: 'messages_menu_dispute_resolution',
            content: ''
        },
        {
            id: 'anti-money-laundering',
            url: 'anti-money-laundering.html',
            translation: 'messages_menu_anti_money_laundering',
            content: ''
        },
        {
            id: 'fairness-rng-testing-methods',
            url: 'fairness-rng-testing-methods.html',
            translation: 'messages_menu_fairness_rng_testing_methods',
            content: ''
        },
        {
            id: 'pay-outs-bonuses',
            url: 'pay-outs-bonuses.html',
            translation: 'messages_menu_pay_outs_bonuses',
            content: ''
        }
    ],
    clientIp: {},
    promotionsPage: '',
    promotion: '',
    promoTitle: '',
    isShowCookiesBar: {
        status: false,
        height: 0
    },
    frenchLocations: ["FR", "BL", "GP", "MF", "MQ", "NC", "PF", "PM", "RE", "TF", "WF", "GF", "YT"],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_CONTENT:
            return {
                ...state,
                content: {
                    ...state.content,
                    status: action.payload.data.status,
                    maintenanceWhitelistIps: action.payload.data.maintenanceWhitelistIps
                },
                isContentLoaded: true,
                isLoading: false
            };
        case GET_ALL_CMS_CONTENT:
            if (action.payload.authorization) {
                useToken('token', action.payload.authorization.token);
                useToken('refreshToken', action.payload.authorization.refresh_token);
                useToken('expires_in', action.payload.authorization.expires_in);
            }
            const cmsPagesWithContent = state.cmsPages.map(page => {
                const content = action.payload.data.find(data => data.slug === page.id)?.content || page.content;
                return {
                    ...page,
                    content,
                    isLoading: false,
                    promotionsPage: action.payload.data.find(data => data.slug === 'promotions')?.content
                }
            });
            return {
                ...state,
                cmsPages: cmsPagesWithContent,
                promotionsPage: action.payload.data.find(data => data.slug === 'promotions').content,
                isLoading: false
            };
        case GET_PROMOTION:
            const promoData = JSON.parse(action.payload.data.content);
            return {
                ...state,
                isLoading: false,
                promotion: promoData.url,
                promoTitle: promoData.title
            };
        case CMS_CONTENT_REQUESTING:
            return {
                ...state,
                isLoading: true
            };
        case GET_PROMOTION_ERROR:
            return {
                ...state,
                isLoading: false,
                promotion: action.payload.errors.detail,
                promoTitle: ''
            };
        case CLEAN_PROMOTION:
            return {
                ...state,
                promotion: '',
                promoTitle: ''
            };
        case DETECT_CLIENT_IP:
            return {
                ...state,
                clientIp: action.payload,
                isClientIpLoaded: true
            };
        case SHOW_COOKIES_BAR:
            return {
                ...state,
                isShowCookiesBar: {
                    status: action.status,
                    height: action.height,
                }
            };
        default:
            return state
    }
}

export const getClientIp = () => {
    const success = (payload) => ({
        type: DETECT_CLIENT_IP,
        payload
    });
    return dispatch => detectClientIp()
        .then(res => res.text())
        .then(res => {
            let data = res.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
            data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
            const jsonData = JSON.parse(data);
            dispatch(success({
                ip: jsonData.ip,
                loc: jsonData.loc
            }))
        })
        .catch((err) => console.error(`The error occurred on Cloudflare request: ${err}`));
};


export const contentFromCms = (payload) => ({
    type: GET_CONTENT,
    payload
});

export const allCmsContent = (payload) => ({
    type: GET_ALL_CMS_CONTENT,
    payload
});

export const showCookiesBar = (status, height) => ({
    type: SHOW_COOKIES_BAR,
    status,
    height
});

export const getPromotionContent = (lang, slug) => {
    const requesting = () => ({
        type: CMS_CONTENT_REQUESTING
    });
    const success = (payload) => ({
        type: GET_PROMOTION,
        payload
    });
    const fail = (payload) => ({
        type: GET_PROMOTION_ERROR,
        payload
    });
    return dispatch => {
        dispatch(requesting());
        promotionRequest(lang, slug)
            .then(res => dispatch(success(res.data)))
            .catch(err => err.json().then(res => dispatch(fail(res))))
    }
};

export const cleanPromotion = () => ({
    type: CLEAN_PROMOTION
});
