import { registrationRequest, csrfRequestReistration } from '../../workers/registration';
import moment from "moment";
import { getUserInformation } from './user';
import { userBalance } from '../reducers/bank';
import { useToken } from "../../components/utils/useToken";

const UPDATE_FORM = 'UPDATE_REGISTRATION_FORM';
const SUBMIT_FORM_SUCCESS = 'SUCCESS_REGISTRATION_FORM';
const SUBMIT_FORM_FAIL = 'FAIL_REGISTRATION_FORM';
const ACCESS_ASSETS_FOR_SECOND_STEP  = 'ACCESS_ASSETS_FOR_SECOND_STEP';
const COMBINE_FORM  = 'COMBINE_FORM';
const RESOLVE_ERROR = 'RESOLVE_ERROR';
const REMOVE_SERVER_ERROR = 'REMOVE_SERVER_ERROR';
const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST';
const SET_UNAUTHORIZED = 'SET_UNAUTHORIZED';
const CLEAR_REGISTRATION_SUBMIT = 'CLEAR_REGISTRATION_SUBMIT';

const initialState = {
    submit: {
        isSubmit: false,
        isSuccess: false,
        isFail: false
    },
    access: {
        secondStep: false
    },
    form: {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        city: '',
        gender: '',
        countryPhoneCodePrefix: '',
        phone: '',
        currency: 'EUR',
        birthDate: '',
        address: '',
        zipCode: '',
        country: '',
        marketingAllowed: false,
        affiliateId: null,
        tracker: null,
        language: ''
    },
    response: {
        errors: []
    },
    serverErrorKeys: {
        pointers: {
            csrf: 'csrf_error',
            firstName: 'form_register_form_firstname_wrong',
            lastName: 'form_register_form_lastname_wrong',
            username: 'form_register_form_username_wrong',
            birthDay: 'form_register_form_day_incorrect',
            birthMonth: 'form_register_form_month_incorrect',
            birthYear: 'form_register_form_year_incorrect',
            birthDate: 'form_register_form_birthdate_wrong',
            phone: 'form_register_form_phone_wrong',
            address: 'form_register_form_address_wrong',
            city: 'form_register_form_city_wrong',
            zipCode: 'form_register_form_postcode_wrong',
        },
        details: {
            'Email is already taken.': 'email_exist',
            'This value is not a valid email address.': 'email_not_valid',
            'This value is not valid.': 'email_not_valid',
            'blocking_ip': 'blocking_ip'
        }
    },
    isUnauthorized: false,
    countrySelect: [],
    isCountriesLoaded: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_FORM:
            const { email, password, firstname, lastname, username, affiliateId, tracker } = action.payload;
            return {
                ...state,
                access: {
                    secondStep: true
                },
                isUnauthorized: false,
                form: {
                    ...state.form,
                    email,
                    firstname,
                    lastname,
                    username,
                    password,
                    affiliateId: affiliateId === '' ? null : affiliateId,
                    tracker: tracker === '' ? null : tracker,
                }
            };
        case COMBINE_FORM:
            let { birthYear, birthMonth, birthDay, gender, countryPhoneCodePrefix, phone, address, city, zipCode, country, marketingAllowed, language } = action.payload;
            return {
                ...state,
                form: {
                    ...state.form,
                    city,
                    gender,
                    countryPhoneCodePrefix,
                    phone,
                    birthDate: moment(`${birthYear}-${birthMonth}-${birthDay}`).format( 'YYYY-MM-DD'),
                    address,
                    zipCode,
                    country,
                    marketingAllowed,
                    language
                },
                submit: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false
                }
            };
        case SUBMIT_FORM_SUCCESS:
            //TODO CHECK PATH FOR TOKEN
            useToken('unlogged', '', 'delete');
            useToken('token', action.payload.data.token);
            useToken('refreshToken', action.payload.data.refresh_token);
            useToken('expires_in', action.payload.data.expires_in);
            return {
                ...state,
                submit: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                }
            };
        case SUBMIT_FORM_FAIL:
            return {
                ...state,
                submit: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: true
                },
                response: {
                    errors: action.payload.errors
                }
            };
        case ACCESS_ASSETS_FOR_SECOND_STEP:
            return {
                ...state,
                access: {
                    secondStep: action.payload
                }
            };
        case RESOLVE_ERROR:
            return {
                ...state,
                submit: {
                    ...state.submit,
                    isFail: false
                }
            };
        case REMOVE_SERVER_ERROR:
            const cleanErrorArray = state.response.errors.filter(item => item.source?.pointer !== action.payload);
            return {
                ...state,
                response: {
                    errors: action.payload === 'email' ? [] : cleanErrorArray
                }
            };
        case GET_COUNTRIES_LIST:
            return {
                ...state,
                countrySelect: action.payload.data.allowed_countries,
                isCountriesLoaded: true
            };
        case SET_UNAUTHORIZED:
            return {
                ...state,
                isUnauthorized: true,
                submit: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                },
            };
        case CLEAR_REGISTRATION_SUBMIT:
            return {
                ...state,
                submit: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                },
            };
        default:
            return state;
    }
};

export const updateRegistrationForm = (payload) => ({
    type: UPDATE_FORM,
    payload
});

export const accessSecondRegistrationStep = (payload) => ({
    type: ACCESS_ASSETS_FOR_SECOND_STEP,
    payload
});

export const combineForm = (payload) => ({
    type: COMBINE_FORM,
    payload
});

export const resolveError = () => ({
    type: RESOLVE_ERROR
});

export const removeServerError = (payload) => ({
    type: REMOVE_SERVER_ERROR,
    payload
});

export const createCountrySelect = (payload) => ({
    type: GET_COUNTRIES_LIST,
    payload
});

export const submitRegistrationForm = (payload) => {
    const success = (data) => ({
        type: SUBMIT_FORM_SUCCESS,
        payload: data
    });
    const fail = (error) => ({
        type: SUBMIT_FORM_FAIL,
        payload: error
    });
    const setUnauthorized = () => ({
        type: SET_UNAUTHORIZED
    });
    return dispatch => {
        csrfRequestReistration()
            .then(token =>
                registrationRequest({...payload, csrf: token.data.data.token})
                    .then(res => {
                        dispatch(success(res.data));
                        //TODO CHECK PATH FOR TOKEN
                        dispatch(getUserInformation(res.data.data.token));
                        dispatch(userBalance(res.data.data.token));
                    })
                    .catch(err => {
                        if (err.status === 401) return dispatch(setUnauthorized());
                        return dispatch(fail(err.data));
                    })
            )
            .catch((err) => dispatch(fail({errors: [{source: {pointer: err.status}, detail: [`${err.statusText}`]}]})));
    }
};

export const clearRegistrationSubmit = () => ({
    type: CLEAR_REGISTRATION_SUBMIT,
});
